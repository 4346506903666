<template>
  <div class="change-municipality-message">
    <h4>Для отображения отчёта, пожалуйста, выберите Муниципальное образование</h4>
  </div>
</template>

<script>

export default {
  name: 'show-change-municipality-message',
};
</script>

<style lang="scss" scoped>
  .change-municipality-message {
    text-align: center;
    margin-top: 15px;
  }
</style>
