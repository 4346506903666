import axios from '@/services/axios';

export class ScheduleReport {
  static export({ scheduleId }: {
    scheduleId: number,
  }): Promise<{
      file: Blob
    }> {
    return axios({
      url: `/report/export/schedule/${scheduleId}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then(({ data }: { data: Blob }) => ({
        file: data,
      }));
  }
}
