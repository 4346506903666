<template>
  <div class="passenger-transportation">
    <h3 class="text-center">Пассажирские перевозки</h3>
    <h3 class="text-center">Расписания</h3>

    <message v-if="showChangeMunicipality"></message>

    <template v-else>
      <div class="action-wrapper" v-show="!printing">
        <div class="text-center">
          <button class="btn btn-warning" @click.prevent="printReport()">Печать</button>
        </div>
      </div>
      <h5 class="text-center"><b>Муниципальное образование {{municipality.name}}</b></h5>
      <div class="table-schedule" v-if="Object.keys(computedSchedules).length > 0">
        <table>
          <thead>
            <tr>
              <th>№ п/п</th>
              <th>Расписания маршрутов</th>
              <th>Путь</th>
              <th>Дата изменения расписания</th>
              <th>День недели</th>
              <th>Сезонность</th>
              <th>Перевозчик</th>
              <th>ИНН перевозчика</th>
              <th>Организатор Деятельности</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(schedule, index) in Object.values(computedSchedules)">
              <tr :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-left" colspan="8">{{ schedule[0].route_group.route.name }}</td>
              </tr>
              <tr v-for="element in schedule" :key="`element ${element.id}`">
                <td>
                  <button v-if="canExportTable && !printing" class="btn btn-export" @click.prevent="exportTable(element)">Экспорт таблицы</button>
                  <span v-else>---</span>
                </td>
                <td class="text-left">
                  <span>{{ element.name }}</span>
                </td>
                <td>{{ element.route_group.name }}</td>
                <td>{{ element.updated_at | formattedDate }}</td>
                <td>{{ element.work_on | workOn }}</td>
                <td>{{ element.season | seasons }}</td>
                <td>{{ element.transporter ? element.transporter.name : '' }}</td>
                <td>{{ element.transporter ? element.transporter.inn : '' }}</td>
                <td>{{ element.route_group.route.municipality.name  }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Message from '@/components/shared/show-change-municipality-message';
import { ScheduleReport } from '@/services/api/report/schedule-report';
import { ACL } from '@/modules/acl/acl-service';
import { PROJECT_TITLE } from '@/constants/Global';

export default {
  name: 'schedule',
  components: {
    Message,
  },
  data() {
    return {
      printing: false,
    };
  },
  methods: {
    ...mapActions({
      loadSchedules: 'schedule/loadSchedules',
      setCurrentMunicipality: 'municipalities/setCurrentMunicipality',
    }),
    loadDataSchedules() {
      this.loadSchedules({ all: true, municipalityId: this.municipality.id })
        .then(() => {
          const query = {};

          if (this.municipality.id) {
            query.municipalityId = this.municipality.id;
          }
          this.$router.push({
            query,
          }).catch(() => {});
        })
        .catch(() => {
          this.canPrint = false;
        });
    },
    exportTable(schedule) {
      ScheduleReport.export({
        scheduleId: schedule.id,
      })
        .then(({ file }) => {
          const url = URL.createObjectURL(new Blob([file], {
            type: 'application/vnd.ms-excel',
          }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Расписание ' + schedule.name + '.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {});
    },
    printReport() {
      this.printing = true;
      let header = document.querySelector('header.header');
      let content = document.querySelector('.content');
      let table = document.querySelector('.kommun-transportation');
      let sticky = document.querySelectorAll('.kommun-transportation .table-transporter table thead th');

      header.hidden = true;
      content.style.margin = 0;
      table.style.overflow = 'visible';
      for (let el = 0; el < sticky.length; el++) {
        sticky[el].style.position = 'static';
      }
      document.title = `Расписания`;

      this.$nextTick().then(() => {
        window.print();

        this.printing = false;
        document.title = PROJECT_TITLE;
        header.hidden = false;
        content.style.margin = '';
        table.style.overflow = '';
        for (let el = 0; el < sticky.length; el++) {
          sticky[el].style.position = '';
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      municipality: 'municipalities/current',
      showChangeMunicipality: 'municipalities/isCurrentGeneral',
      schedules: 'schedule/schedules',
    }),
    computedSchedules() {
      return this.schedules.reduce((a, c) => {
        if (c.route_group !== null && c.route_group.route !== null) {
          let index = a.findIndex(value => value[0].route_group.route.id === c.route_group.route.id);
          if (index === -1) {
            a.push([]);
            index = a.length - 1;
          }
          a[index].push(c);
        }
        return a;
      }, []);
    },
    canExportTable() {
      return ACL.can('report_export.schedule');
    },
  },
  watch: {
    municipality: function() {
      this.loadDataSchedules();
    },
  },
  created() {
    if (Object.keys(this.$route.query).length) {
      const routeQuery = this.$route.query;
      const municipalityId = routeQuery.municipalityId;
      if (municipalityId) {
        this.setCurrentMunicipality(Number(municipalityId));
      }
    }

    this.loadDataSchedules();
  },
  filters: {
    formattedDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    },
    workOn(workOn) {
      const days = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС', '', ''];
      workOn = workOn.map(day => {
        return days[day - 1];
      });
      return workOn.filter(function(el) { return el; }).join(',');
    },
    seasons(seasons) {
      seasons = seasons.filter(function(el) { return el; });
      if (seasons.length === 2) {
        return 'Круглогодично';
      } else if (seasons[0] === '1') {
        return 'Весна-Лето';
      } else if (seasons[0] === '2') {
        return 'Осень-Зима';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .passenger-transportation {
    padding: 10px;
    height: calc(100vh - 56px);
    overflow: auto;

    .table-schedule {
      height: calc(100vh - 150px);
      border-top: 1px solid #e4e4e4;

      table {
        width: 100%;
        min-width: 800px;
        margin: 0;

        border-collapse: separate;
        border-spacing: 0;
        border: none;

        td {
          border-bottom: 1px solid rgb(228, 228, 228);
          border-right: 1px solid rgb(228, 228, 228);
          border-top: none;
          border-left: 1px solid rgb(228, 228, 228);
        }

        th {
          border: 1px solid rgb(228, 228, 228);
        }

        thead tr:first-child th {
          border-top: none;
        }

        a {
          color: #007bff;

          &.router-link-active, &:hover {
            color: var(--main-color);
          }
        }

        thead {
          th {
            position: sticky;
            top: -10px;
            background-color: #fff;
            padding: 10px 0;
          }
        }

        td, th {
          padding: 0 5px;
          text-align: center;
        }
      }
    }

    .btn-export {
      background-color: var(--main-color);
      color: #fff;
      margin: 5px 0;
    }
  }
</style>
